import {
	API_BASE_URL,
	FILE_BASE_URL,
	SOCKET_ENDPOINT_URL,
	WS_HOST
} from 'constants/ApiConstant';

const dev = {
	API_ENDPOINT_URL: "http://localhost/nexium/back/back/api/auth",
	FILE_ENDPOINT_URL: "http://localhost/nexium/back/back/public/storage/",
	IMPORT_SAMPLE_PARENT_FILE: "http://localhost/nexium/back/back/public/storage/demo-imports/import-parents.xlsx",
	IMPORT_SAMPLE_STUDENTS_FILE: "http://localhost/nexium/back/back/public/storage/demo-imports/import-students.xlsx",
	SOCKET_ENDPOINT_URL: 'http://localhost/nexium/back/back/api/',
	WS_HOST: 'http://localhost',
	PUBLIC_LOGO_PATH: 'http://localhost/nexium/back/back/public/img/logo.png',
	PUBLIC_NAV_COLLAPSED_LOGO_PATH: 'http://localhost/nexium/back/back/public/img/logo-sm.png',
	PUBLIC_FAVICON_LOGO_PATH: 'http://localhost/nexium/back/back/public/img/favicon_logo.ico',
	img: "/img/avatars/user.png",
	APP_VERSION: "1.0.6"
};

const prod = {
	WS_HOST: WS_HOST,
	SOCKET_ENDPOINT_URL: SOCKET_ENDPOINT_URL,
	API_ENDPOINT_URL: API_BASE_URL,
	FILE_ENDPOINT_URL: FILE_BASE_URL,
};

const test = {
	API_ENDPOINT_URL: "http://localhost/nexium/back/back/api/auth",
	FILE_ENDPOINT_URL: "http://localhost/nexium/back/back/public/storage/",
	IMPORT_SAMPLE_PARENT_FILE: "http://localhost/nexium/back/back/public/storage/demo-imports/import-parents.xlsx",
	IMPORT_SAMPLE_STUDENTS_FILE: "http://localhost/nexium/back/back/public/storage/demo-imports/import-students.xlsx",
	SOCKET_ENDPOINT_URL: 'http://localhost/nexium/back/back/api/',
	WS_HOST: 'http://localhost',
	PUBLIC_LOGO_PATH: 'http://localhost/nexium/back/back/public/img/logo.png',
	PUBLIC_NAV_COLLAPSED_LOGO_PATH: 'http://localhost/nexium/back/back/public/img/logo-sm.png',
	PUBLIC_FAVICON_LOGO_PATH: 'http://localhost/nexium/back/back/public/img/favicon_logo.ico',
	img: "/img/avatars/user.png",
	APP_VERSION: "1.0.6"
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
